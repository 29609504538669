<template>
  <div>
    <w-top-bar>Clientes</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="py-2 text-gray-900">Resumen General</div>
      <div
        v-if="false"
        class="flex flex-wrap space-y-3 md:flex-nowrap md:space-y-0 md:space-x-6"
      >
        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">Balance de cuenta</div>
              <div class="text-base font-semibold text-gray-900">
                $40,231.22
              </div>
            </div>
          </div>
          <div
            class="px-4 py-2 text-xs border-t bg-sand-400 border-sand-500 text-base-500"
          >
            Ver todo
          </div>
        </div>

        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">Próxima Actividad</div>
              <div class="text-base font-semibold text-gray-900">
                $40,231.22
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs bg-sand-500 text-base-500">
            View all
          </div>
        </div>

        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">Account balance</div>
              <div class="text-base font-semibold text-gray-900">
                $40,231.22
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs bg-sand-500 text-base-500">
            Ver todo
          </div>
        </div>
      </div>
      <div class="mt-0">
        <w-customers :items="customers" />
      </div>
    </div>
  </div>
</template>

<script>

import { makeFindMixin } from "feathers-vuex"; // Step 1
    export default {
          name: "Dashboard",
  mixins: [
    makeFindMixin({ service: "customers" }),
    // makeFindMixin({ service: "products", name: "pastproducts" }),
  ], // Step 2
  computed: {
    customersParams() {
      return {
        query: {
          $limit: 10,
          $select: ["name", "phone", "email", "inscriptions", "totalPaid", "createdAt"],
          $sort: {
            createdAt: -1
          }
        },
        inscriptions: true
      }; // Step 3
    },
  },
  methods: {
    reload() {
      console.log("reload")
    }
  },
    }
</script>

<style lang="scss" scoped>
</style>
