import { render, staticRenderFns } from "./WCustomers.vue?vue&type=template&id=7121be1f&scoped=true&"
import script from "./WCustomers.vue?vue&type=script&lang=js&"
export * from "./WCustomers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7121be1f",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WModalImage from '@/components/WModalImage.vue'
import WPrice from '@/components/WPrice.vue'
import WUploadReceipt from '@/components/WUploadReceipt.vue'
autoInstallComponents(component, {WModalImage,WPrice,WUploadReceipt})
